import React, { Component } from 'react';
import axios from 'axios'
import CrossLinks from '@otavamedia/om-component-library/lib/CrossLinks'

const Link = ((props) => {
  const { to, children, className, onKeyDown, onClick } = props

  const tagProps = {
    href: to.link,
    target: '_blank',
    rel: 'noreferrer noopener',
    onKeyDown,
  }

  return (
    <a {...tagProps} className={([className]).join(' ').trim()} onClick={onClick}>
      {children}
    </a>
  )
})

class CrossLinkModel {
  link
  title
  siteName
  date
  thumbnail

  constructor (feedData) {
    this.link = feedData.link
    this.title = feedData.title.replace(/kommentit$/, '').replace(/^Artikkelin /, '')
    this.siteName = feedData.magazine || ''
    this.date = new Date(feedData.pubDate).toISOString()
    this.thumbnail = feedData.thumbnail_url
  }
}

class App extends Component {

  getCrossLinks() {
    const url = window.CROSSLINKS_BACKEND_URL || 'https://wp.tekniikanmaailma.fi/wp-json/outdoor/uusimmat'
    return axios.get(url).then((result) => {
      if (result.data && result.data[0]) {
        return result.data.map((res) => new CrossLinkModel(res)).filter((item) => item.link.indexOf(window.location.origin) === -1)
      }
      return []
    })
  }

  render() {
    return (
      <CrossLinks getCrossLinks={() => this.getCrossLinks()} LinkComponent={Link}/>
    );
  }
}

export default App;
